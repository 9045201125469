<template>
  <panel-main ref="panelMainRef">
    <!-- 表格区域 -->
    <div class="table-content">
      <!-- 表格 -->
      <ml-table
        ref="tableRef"
        :tableData="tableData"
        :templateData="templateData"
        :pageParams="pageParams"
        tableHeight="100%"
        :useJumpClick="false"
        :templateAuths="{
          btnActive: ['normal_update', 'normal_delete'],
          status: ['enableDisable'],
        }"
      >
        <template #status="{ row }">
          <div>
            <auth-switch
              :disabled="row.id === '1000000000000000000'"
              menuCode="enableDisable"
              :beforeChange="userStatusSwitch(row)"
              v-model="row.status"
            ></auth-switch>
          </div>
        </template>

        <template #btnActive="{ row }">
          <auth-button
            :disabled="row.id === '1000000000000000000'"
            menuCode="normal_update"
            type="warning"
            size="small"
            @handleClick="jumpAction(row, 'edit')"
            >编辑</auth-button
          >

          <auth-button
            :disabled="row.id === '1000000000000000000'"
            menuCode="normal_delete"
            type="danger"
            size="small"
            @handleClick="openDeleteDialog(row)"
            >删除</auth-button
          >
        </template>
      </ml-table>
    </div>
  </panel-main>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed,
  nextTick,
  watch,
} from "vue";
import { useTableParamsModule, usePageModule } from "@/utils/mixin";
import { enableDisableOpts } from "@/utils/options";
import { testPwd } from "@/utils/regExp";
import { ruleNormalPhone } from "@/utils/formRules";

const props = defineProps({
  tabValue: {
    type: [String, Number],
    default: "",
  },
});

const { proxy } = usePageModule();

// 面板
// ref
const panelMainRef = ref();

// 表格配置
const {
  resetParams,
  tableData,
  pageParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  tableRef,
  filterParams,
} = useTableParamsModule({
  fetchTableName: "fetchGetRolePageList",
  setCallBackRes(res) {
    const { total, result } = res;

    const data = result.map((d) => {
      const isAdmin = d.phone === "admin";
      return {
        ...d,
        isAdmin,
      };
    });

    return {
      total,
      result: data,
    };
  },
});

// 表格模板数据
const templateData = [
  {
    type: "index",
    label: "序号",
    width: "120",
    align: "left",
  },
  {
    prop: "name",
    label: "角色名称",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "relationCount",
    label: "关联用户数量",
    minWidth: "240",
    align: "left",
  },
  {
    prop: "status",
    label: "状态",
    minWidth: "240",
    showTemplate: true,
    align: "left",
  },
  {
    prop: "createTime",
    label: "创建时间",
    minWidth: "200",
    align: "left",
  },
  {
    prop: "btnActive",
    label: "操作",
    showTemplate: true,
    align: "left",
    fixed: "right",
    minWidth: "150",
  },
];

// *********************************************************************************************************
// 表格数据操作  START
// *********************************************************************************************************

// 打开弹窗
const openDeleteDialog = (item) => {
  // 提示

  panelMainRef.value.askDialog({
    type: "danger",
    title: "删除角色",
    askTips: ["您确定要删除该角色吗？"],
    confirm(close) {
      let result = "";
      if (item) {
        const { id } = item;
        result = id;
      }
      dispatchDelete(result, close);
    },
  });
};
// 调用删除
const dispatchDelete = async (id, close) => {
  try {
    await proxy.$storeDispatch("fetchRemoveRole", id);
    proxy.$successMsg("删除成功");
    getTableList();
    close();
  } catch (error) {
    console.log(error);
    close();
  }
};

// 启用禁用开关
const userStatusSwitch = (item) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = item;
        const status = item.status ? 0 : 1;
        const param = {
          id,
          status,
        };
        const res = await modifySystemUserStatus(param);
        resolve(res);
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  };
};

// 修改用户状态
const modifySystemUserStatus = async (params) => {
  try {
    await proxy.$storeDispatch("fetchModifyRole", { params });
    getTableList();
    return true;
  } catch (error) {
    return false;
  }
};

/* 用户弹窗 */
// ref
const userDialogRef = ref();
const bucketFormRef = ref();

// 表单信息
const userFormDataDefault = () => ({
  id: "",
  headPortrait: "",
  phone: "",
  name: "",
  password: "",
  againPassword: "",
  // role: [],
  status: 1,
});
const userFormData = reactive(userFormDataDefault());

const bucketFormRules = computed(() => {
  // 是否是编辑
  const { isEdit } = userDialogConfig.value;
  const rules = {
    phone: ruleNormalPhone(),
    name: [
      {
        required: true,
        trigger: "change",
        message: "请输入姓名",
      },
    ],
    password: [],
    againPassword: [],
    // role: [
    //   {
    //     required: true,
    //     trigger: "change",
    //     message: "请选择角色",
    //   },
    // ],
  };

  const pwdRules = [
    {
      validator: testNormalPwd,
    },
    {
      validator: testPwdIdentical,
    },
  ];
  rules.password = pwdRules;
  rules.againPassword = pwdRules;
  if (!isEdit) {
    rules.password.unshift({
      required: true,
      trigger: "change",
      message: "请先输入登陆密码",
    });
    rules.againPassword.unshift({
      message: "请先确认密码",
      trigger: "change",
      required: true,
    });
    return rules;
  } else {
    rules.password = pwdRules;
    rules.againPassword = pwdRules;
    return rules;
  }
});

/* 修改密码 */
// 校验常规密码
const testNormalPwd = (rule, value, callback) => {
  const len = (value || "").toString().length;
  if ((value && len < 6) || len > 20) {
    callback(new Error("请输入6-20位的密码"));
  } else {
    callback();
  }
};
// 验证密码是否一致
const testPwdIdentical = (rule, value, callback) => {
  if (userFormData.password || userFormData.againPassword) {
    if (userFormData.password != userFormData.againPassword) {
      callback(new Error("两次密码不一致"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

// 选中的数据
const chooseItem = reactive({});

// 弹窗配置
const userDialogConfig = computed(() => {
  const { actionType } = chooseItem;

  const isEdit = actionType == "edit";
  const actionTypeText = isEdit ? "修改" : "添加";
  const dialogTitle = `${actionTypeText}用户`;

  // 表单操作接口
  const formFetchName = isEdit ? "fetchModifySystemUser" : "fetchAddSystemUser";

  return { dialogTitle, actionTypeText, formFetchName, isEdit };
});

// 打开弹窗
// 跳转设置页面
const jumpAction = (data = {}, actionType) => {
  const id = data.id || "";
  const params = { id, actionType };
  proxy.$navigateTo("/rolePermissionManagementAction", params);
};

// 表格选中数据
const changeTableItem = ref({});

/* 头像数据 */
const imageFile = ref([]);
watch(
  () => imageFile.value,
  (nVal) => {
    // console.log(nVal);
    userFormData.headPortrait = nVal.map((d) => d.relUrl).join(",");
  },
  {
    deep: true,
  }
);

// 是否是编辑
const isEditForm = computed(() => {
  const { isEdit } = userDialogConfig.value;

  return isEdit;
});

// 表单提交
const userSubmitForm = async (closeLoading) => {
  const testRes = await proxy.$testForm(closeLoading, bucketFormRef.value);
  if (!testRes) {
    return;
  }

  const { actionTypeText, formFetchName } = userDialogConfig.value;

  let result = proxy.$removeDecontextAttr(userFormData);
  // console.log(result);
  try {
    await proxy.$storeDispatch(formFetchName, result);
    proxy.$successMsg(actionTypeText + "成功");
    closeLoading();
    userDialogRef.value.closeDialog();
    getTableList();
  } catch (error) {
    closeLoading();
  }
};

// *********************************************************************************************************
// 表格数据操作  END
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 START
// *********************************************************************************************************

// 查询用户详情
const getSystemUserDetailsReporting = async (id) => {
  try {
    const res = await proxy.$storeDispatch(
      "fetchGetSystemUserDetailsReporting",
      {
        id,
      }
    );
    const result = res.result;
    return result;
  } catch (error) {
    return {};
  }
};

// *********************************************************************************************************
// 查询 END
// *********************************************************************************************************

// 模拟数据
// const data = {
//   result: [
//     {
//       phone: "18595852485",
//       status: true,
//     },
//   ],
//   total: 0,
// };

defineExpose({
  resetParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
});
</script>

<style lang="scss" scoped>
.table-content {
  height: 100%;

  // 项目成员

  .head-sculpture-group {
    display: flex;
    .head-box {
      margin-right: 8px;
    }
  }
}

.byte-group {
  width: 100%;
  display: flex;
  margin-top: 15px;
  position: relative;
  margin-bottom: 10px;
  .item {
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.show-line-tips {
  position: absolute;
  right: 4px;
  width: auto;
  bottom: -25px;
}
</style>

<style lang="scss">
.file-type-form {
  .storage-capacity-item {
    margin-bottom: 27px;
  }
}
</style>
